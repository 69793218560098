import { Button, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { login } from "../util/auth";

const url = require('url');

export default function uth({
  userSigner
}) {

	const [jwt, setJwt] = useState();
	const [error, setError] = useState();
	const [userIdToken, setUserIdToken] = useState()

    useEffect(() => {
			const href = window.location.href;
			console.log(href)
			const parsedUrl = url.parse(href, true);
			const query = parsedUrl.query;
			if (query.id) {
				setUserIdToken(query.id);
			}
    }, []);

  return (
    <div>
      <div class="auth-button">
        {/* <h2>Connect your wallet, then click "Authenticate"</h2>
        <Divider /> */}
        <div>
          <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
							setError(null);
              const result = await login({ 
								signer: userSigner,
								userIdToken: userIdToken // This is a JWT which will be decoded in the lambda
							});
							if (result.token) {
              	setJwt(result);
							}
							else {
								setError(result.error)
							}
            }}
          >
            Let me in!
          </Button>
          {jwt && <div class="auth-success">Success! Head back to Discord for your updated Role!</div>}
					{error && <div class="auth-error">Error: {error}</div>}
        </div>
      </div>
    </div>
  );
}
